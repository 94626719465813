import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ExternalLink from '../ExternalLink/ExternalLink';
import CommonData from '../../data/common/common';
import { LocaleNameSpaceKeys } from '../../localization/translations';
import './style.scss';

interface OwnProps {
  className?: string;
}

type Props = OwnProps;

const ImportantMessage: React.FC<Props> = ({ className = '' }) => {
  const { t } = useTranslation();
  const servicingEmail = t('emails.servicing');

  const ariaLabels = {
    email: t(`${LocaleNameSpaceKeys.COMMON}:email`),
    tollFree: t(`${LocaleNameSpaceKeys.COMMON}:tollFree`),
  };

  const { mortgageServicing } = CommonData.tollFreeCustomerServiceNumber;

  return (
    <div className={`ImportantMessage ${className}`}>
      <h4 className="h4">{t('importantMessage.heading')}</h4>
      <p>
        <Trans
          t={t}
          i18nKey="importantMessage.description"
          values={{
            email: servicingEmail,
            phone: CommonData.tollFreeCustomerServiceNumber.mortgageServicing,
          }}
        >
          <ExternalLink
            href={`mailto:${servicingEmail}`}
            aria-label={`${ariaLabels.email} ${servicingEmail}`}
          />
          <ExternalLink
            href={`tel:${mortgageServicing}`}
            aria-label={`${ariaLabels.tollFree} ${mortgageServicing}`}
          />
        </Trans>
      </p>
    </div>
  );
};

export default ImportantMessage;
