import React from 'react';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';
import { PageProps, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout, { LayoutOptions, HeroSectionColors } from '../components/Layout/Layout';
import { LocaleNameSpaceKeys } from '../localization/translations';
import { getPathFromRouteKey } from '../shared/utils/getPathFromRouteKey';
import RouteKeys from '../../config/RouteKeys';
import ExternalLink from '../components/ExternalLink/ExternalLink';
import InfoBlock from '../components/InfoBlock/InfoBlock';
import Icon, { IconTypes } from '../components/Icon/Icon';
import { useLocaleContext } from '../context/Locale';
import { LocaleTag } from '../../config/locales';
import { ButtonLinkSize } from '../components/ButtonLink/ButtonLink';
import CommonData from '../data/common/common';
import Link from '../components/Link/Link';
import ImportantMessage from '../components/ImportantMessage/ImportantMessage';

import './style.scss';

type Props = PageProps & WithTranslation;

const IndexPage: React.FC<Props> = (props) => {
  const { t, data } = props;
  const { activeLocaleTag } = useLocaleContext();

  const dynamicHeroSectionOptions = [
    {
      heroImage: {
        fileName: 'hero-image-2.jpg',
        alt: 'Image',
      },
      blockText: t('heroSection.followTheFearless'),
    },
    {
      heroImage: {
        fileName: 'hero-image-5.jpg',
        alt: 'Image',
      },
      blockText: t('heroSection.makeTheWayForOutsiders'),
    },
    {
      heroImage: {
        fileName: 'hero-image-6.jpg',
        alt: 'Image',
      },
      blockText: t('heroSection.powerToTheGrinders'),
    },
  ];

  const layoutOptions: LayoutOptions = {
    seo: {
      title: t('seo.title'),
    },
    shouldEnableMaxWidth: false,
    heroSection: {
      options: {
        accentColor: HeroSectionColors.PARROT_GREEN,
        ...dynamicHeroSectionOptions[Math.floor(Math.random() * dynamicHeroSectionOptions.length)],
      },
    },
  };

  const getContentBlocks = () => {
    const contentBlocks: Array<any> = t('whyChooseHavenTreeBankBlock.blocks', {
      returnObjects: true,
    });
    return contentBlocks.map((block: any, i) => (
      <React.Fragment key={`${block.heading}-${i}`}>
        <h3 className="h5">{block.heading}</h3>
        <p>{block.body}</p>
      </React.Fragment>
    ));
  };

  const mortgageProScanFindBrokerLink =
    activeLocaleTag === LocaleTag.EN
      ? 'https://mortgageproscan.ca/findabroker'
      : 'https://mortgageproscan.ca/fr/trouvezvotrecourtier';

  const flmImageUrl = (data as any).allFile.edges.find((edge: any) =>
    edge.node.name.includes(`-${activeLocaleTag}`)
  ).node.publicURL;

  const ariaLabels = {
    experLink: `${t('ariaLabelExpertLink')} ${
      CommonData.tollFreeCustomerServiceNumber.mortgageServicing
    }`,
    cdicName: t(`${LocaleNameSpaceKeys.COMMON}:cdic.name`),
  };

  return (
    <Layout options={layoutOptions} className="Home">
      <div className="Home__max-width-container">
        <ImportantMessage />
        <div className="grid-container Home__generic-gap">
          <div className="row row-wrap Home__intro-wrapper">
            <section className="column column-100">
              <div className="Home__generic-gap">
                <h2 className="h3">{t('homeOwnerShipBlock.heading')}</h2>
                <p>{t('homeOwnerShipBlock.description')}</p>
              </div>
              <div className="Home__generic-gap">
                <h2 className="h3">{t('whyChooseHavenTreeBankBlock.heading')}</h2>
                <p>{t('whyChooseHavenTreeBankBlock.description')}</p>
                {getContentBlocks()}
              </div>
            </section>
            {/* <aside className="column column-33">
              <div className="renew-win-container">
                <Link to={getPathFromRouteKey(RouteKeys.CCP.CASHBACK)}>
                  <img src={flmImageUrl} alt={t('ariaLabelPromoImage')} />
                </Link>
              </div>
            </aside> */}
          </div>
        </div>

        <div className="grid-container Home__generic-gap">
          <div className="row row-wrap Home__info-block-wrapper">
            <div className="column column-25">
              <InfoBlock
                heading={t('infoBlocks.clientCentre.heading')}
                icon={IconTypes.HOUSE_IN_HAND}
                buttonOptions={{
                  to: getPathFromRouteKey(RouteKeys.CUSTOMER_CENTRE),
                  label: t(`${LocaleNameSpaceKeys.COMMON}:learnMore`),
                  iconOptions: {
                    icon: IconTypes.ARROW_NEXT,
                  },
                  styleOptions: {
                    isInline: true,
                    size: ButtonLinkSize.SMALL,
                  },
                  ariaLabel: t('infoBlocks.clientCentre.ariaLabel'),
                }}
              >
                <p>
                  <Trans t={t} i18nKey="infoBlocks.clientCentre.body">
                    <ExternalLink href={mortgageProScanFindBrokerLink} />
                  </Trans>
                </p>
              </InfoBlock>
            </div>
            <div className="column column-25">
              <InfoBlock
                heading={t('infoBlocks.brokerCentre.heading')}
                icon={IconTypes.GROUP}
                buttonOptions={{
                  to: getPathFromRouteKey(RouteKeys.BROKER_CENTRE),
                  label: t(`${LocaleNameSpaceKeys.COMMON}:learnMore`),
                  iconOptions: {
                    icon: IconTypes.ARROW_NEXT,
                  },
                  styleOptions: {
                    isInline: true,
                    size: ButtonLinkSize.SMALL,
                  },
                  ariaLabel: t('infoBlocks.brokerCentre.ariaLabel'),
                }}
              >
                <p>
                  <Trans t={t} i18nKey="infoBlocks.brokerCentre.body">
                    <ExternalLink href={mortgageProScanFindBrokerLink} />
                  </Trans>
                </p>
              </InfoBlock>
            </div>
            <div className="column column-25">
              <InfoBlock
                heading={t('infoBlocks.depositServices.heading')}
                icon={IconTypes.BAR_CHART}
                buttonOptions={{
                  to: getPathFromRouteKey(RouteKeys.DEPOSITS),
                  label: t(`${LocaleNameSpaceKeys.COMMON}:learnMore`),
                  iconOptions: {
                    icon: IconTypes.ARROW_NEXT,
                  },
                  styleOptions: {
                    isInline: true,
                    size: ButtonLinkSize.SMALL,
                  },
                  ariaLabel: t('infoBlocks.depositServices.ariaLabel'),
                }}
              >
                <p>
                  <Trans t={t} i18nKey="infoBlocks.depositServices.body">
                    <ExternalLink href={mortgageProScanFindBrokerLink} />
                  </Trans>
                </p>
              </InfoBlock>
            </div>
          </div>
        </div>
      </div>
      {/* Max-width wrapper ends */}
      <div className="Home__need-more-information-wrapper Home__generic-gap">
        <Img
          alt="Image"
          fixed={(data as any).file.childImageSharp.fixed}
          style={{ position: 'relative', width: '100%', height: '245px' }}
          imgStyle={{ backgroundSize: 'cover', backgroundPosition: 'center', height: '245px' }}
        />
        <div className="Home__need-more-information-container">
          <div className="Home__need-more-information-text-block">
            <h2 className="h3">
              {t(`${LocaleNameSpaceKeys.COMMON}:needMoreInformationBlock.heading`)}
            </h2>
            <p>{t(`${LocaleNameSpaceKeys.COMMON}:needMoreInformationBlock.body`)}</p>
            <p>
              <ExternalLink
                key={`expert-link-${activeLocaleTag}`}
                href={`tel:${CommonData.tollFreeCustomerServiceNumber.mortgageServicing}`}
                aria-label={ariaLabels.experLink}
              >
                {CommonData.tollFreeCustomerServiceNumber.mortgageServicing}
              </ExternalLink>
            </p>
          </div>
        </div>
      </div>
      <div className="grid-container Home__max-width-container Home__cdic-wrapper">
        <div className="row row-wrap Home__generic-gap">
          <p className="column column-75">
            {t(`${LocaleNameSpaceKeys.COMMON}:cdicComplianceText`)}
          </p>
          <div className="column column-25">
            <ExternalLink href={CommonData.websites.cdic[activeLocaleTag]}>
              <div className="cdic-logo-wrapper" aria-label={ariaLabels.cdicName}>
                <Icon type={activeLocaleTag === LocaleTag.EN ? IconTypes.CDIC : IconTypes.SADC} />
              </div>
            </ExternalLink>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query {
    file(relativePath: { eq: "generic-image-1.jpg" }) {
      childImageSharp {
        fixed(width: 1400, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    allFile(filter: { relativePath: { regex: "/ccp/cashback//" }, ext: { eq: ".jpg" } }) {
      edges {
        node {
          publicURL
          name
        }
      }
    }
  }
`;

export default withTranslation(LocaleNameSpaceKeys.HOME)(IndexPage);
